// import apiHelper from "../../apiHelper";
import * as API from "../../api/api";
const state = {
    divrtToken: "",
    user: {},
    aclroles: {},
};
const initialState = Object.assign({}, state);
const mutations = {
    RESET_STATE(state) {
        for (let prop in state) {
            state[prop] = initialState[prop]
        }
    },
    SET_USER(state, userObj) {
        state.user = Object.assign({}, userObj);
    },
    SET_ROLES(state, roles) {
        state.aclroles = Object.assign({}, roles);
    },
    SET_DIVRT_TOKEN(state, token) {
        state.divrtToken = token;
    },
};
const actions = {
    async login({ commit }, reqData) {
        try {
            commit("pinaStore/SET_LOADING_STATUS", { property: "LOGIN", pending: true }, { root: true });
            const result = await API.login(reqData);
            commit("pinaStore/SET_LOADING_STATUS", { property: "LOGIN", pending: false }, { root: true });
            if (result.data.status) {
                localStorage.setItem("divrtToken", result?.data?.message?.divrt ? result.data.message.divrt : "");
                localStorage.setItem("lastLoginAt", result?.data?.message?.last_login_at ? result.data.message.last_login_at : "");
                commit("SET_USER", result?.data?.message?.user[0] ? result.data.message.user[0] : {});
                commit("SET_DIVRT_TOKEN", result?.data?.message?.divrt ? result.data.message.divrt : "");
                (result.data.message.divrt != "" && result.data.message.divrt != undefined) ? this.dispatch("pinaStore/getZones", { uid: result?.data?.message?.user ? result.data.message.user.uid : "" }, { root: true }) : "";    
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    },
};
const getters = {
    getterDivrtToken: (state) => {
        return state.divrtToken
    },
    getterUID: (state) => {
        return state.user?.uid ? state.user.uid : ""
    },
    getterLoginUser: (state) => {
        return state.user;
    },
    getterUserName: (state) => {
        return state.user?.name ? state.user.name : ""
    },
    getterUserLType: (state) => {
        return state.user?.ltype ? state.user.ltype.toLowerCase() : ""
    },
    getterACLRoles: (state) => {
        return state.aclroles;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}