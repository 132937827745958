import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import pinaStore from './modules/pinaStore'
import authStore from './modules/authStore'
Vue.use(Vuex);
const store = new Vuex.Store({
  modules:{
    pinaStore:pinaStore,
    authStore:authStore
  },
  plugins: [
    createPersistedState({ paths: ['authStore', 'pinaStore'] })
  ],
});
export default store;