<template>
  <v-app>
    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
    <v-footer app fixed>
      <v-col class="text-center" cols="12">
        <span class="text-caption"
          >Sphere Kiosk Configurator v{{ version }}</span
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
};
</script>
<style lang="sass">
.vue-ip.material-theme
  width:100%
  border: 1px solid black !important
  border-radius: 8px !important

  & input
    color: black !important
    width:55px !important

.vue-ip.material-theme .segment:after
  color: black !important
</style>