import apiHelper from './apiHelper';
// import axios from 'axios';
/**
 * @declaration addDivrtToken
 * @param {*} data is request data received from each API call.
 * @returns data + divrt token
 */
const addDivrtToken = (data) => {
  return Object.assign(data, { divrt: localStorage.getItem("divrtToken") });
}

/**
 * All declarations
 * @param {*} data is request data received from each API call.
 * @returns response
 */
const login = async (data) => {
  return await apiHelper("POST", "/api/v1/login", data)
}
const resetPassword = async (data) => {
  return await apiHelper("POST", "/api/v1/password/forgot", data)
}
const getZoneSummary = async (data) => {
  return await apiHelper("POST", "/api/v1/getZoneSummary", addDivrtToken(data))
}
const getZones = async (data) => {
  return await apiHelper("POST", "/api/v1/zone/find/provider", addDivrtToken(data))
}
const getZonesOverview = async (data) => {
  return await apiHelper(
    "POST",
    "/api/v1/zone/find/minimumProvider",
    addDivrtToken(data)
  );
};
const updatePassword = async (data) => {
  return await apiHelper("POST", "/api/v1/updatePassword", addDivrtToken(data))
}
const getHardwareComponents = async (data) => {
  return await apiHelper("POST", "/api/v1/getHardwareComponents", addDivrtToken(data))
}
const getComponentsList = async (data) => {
  return await apiHelper("POST", "/api/v1/getComponents", addDivrtToken(data))
}
const configHardwareComponent = async (data) => {
  return await apiHelper("POST", "/api/v1/configHardwareComponent", addDivrtToken(data))
}
const submitConfigurationDetails = async (data) => {
  return await apiHelper("POST", "/api/v1/kiosk/configuration", addDivrtToken(data))
}
const getKioskSummary = async (data) => {
  return await apiHelper("POST", "/api/v1/getKioskSummary", addDivrtToken(data))
}
/**
 * export declarations
 */
export {
  login, getZones, getZoneSummary, getHardwareComponents, getComponentsList, configHardwareComponent, updatePassword, resetPassword, submitConfigurationDetails, getKioskSummary, getZonesOverview
}