import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'NavView',
    redirect: '/home',
    component: () => import('../views/NavView.vue'),
    children: [{
      path: '/home',
      name: 'HomeView',
      component: () => import('../views/HomeView.vue'),
    },
    {
      path:'/home/:zid',
      name: 'ZoneConfig',
      component: () => import('../components/home/ZoneConfig.vue')
    },
    {
      path:'/home/:zid/:lid',
      name: 'StartConfig',
      component: () => import('../components/home/StartConfig.vue')
    },
    {
      path:'/home/:zid/:lid/qr',
      name: 'DisplayQR',
      component: () => import('../components/home/DisplayQR.vue')
    },
    {
      path:'/home/:zid/:lid/summary',
      name: 'DisplaySummary',
      component: () => import('../components/home/DisplaySummary.vue')
    }
  ]
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPasswordView',
    component: () => import('../views/ForgotPasswordView.vue'),
  },
  {
    path: '/resetpassword',
    name: 'ResetPasswordView',
    component: () => import('../views/ResetPasswordView.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register', '/forgotpassword'];
  const authRequired = !publicPages.includes(to.path);
  const divrtToken = localStorage.getItem('divrtToken');
  // store.getters["authStore/getterDivrtToken"];
  if (authRequired && (divrtToken == "" || divrtToken == null)) {
    return next('/login');
  }
  next();
})

export default router
