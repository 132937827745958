import * as API from "../../api/api";
const state = {
    zones: [],
    selectedZone: {},
    loadingStatus: {},
    selectedLane: {},
    configuredLaneQRCode: "",
    appSteps: [
        {
            text: "Home",
            disabled: false,
            href: "/#/home",
            alias: "Home"
        }
    ],
    zoneConfigInfo: [],
};
const initialState = Object.assign({}, state);
const mutations = {
    RESET_STATE(state) {
        for (let prop in state) {
            state[prop] = initialState[prop]
        }
    },
    SET_ZONES(state, zones) {
        state.zones = zones;
    },
    SET_APP_STEP(state, step) {
        // 
        // state.appSteps[stepIndex].href = step.href;
        // state.appSteps[stepIndex].text = step.text;
        let stepIndex = state.appSteps.findIndex(a => a.alias == step.type);
        if(stepIndex > -1){
            state.appSteps[stepIndex].href = step.href;
            state.appSteps[stepIndex].text = step.text;
        }else{
            state.appSteps.push({
                text: step.text,
                disabled: false,
                href: step.href,
                alias: step.type
            })
        }
    },
    REMOVE_APP_STEP(state, step){
        state.appSteps.splice(state.appSteps.findIndex((a) => a.alias == step.type) + 1, 1);
    },
    RESET_APP_STEPS(state) {
        state.appSteps = [ {
            text: "Home",
            disabled: false,
            href: "/#/home",
            alias: "Home"
        }]
        // let resetArr = ["Zone", "Config", "QR"];
        // for (var i = 0; i < resetArr.length; i++) {
        //     let stepIndex = state.appSteps.findIndex(a => a.alias == resetArr[i]);
        //     state.appSteps[stepIndex].href = "";
        //     state.appSteps[stepIndex].text = "";
        // }
    },
    SET_SELECTED_ZONE(state, zone) {
        state.selectedZone = Object.assign({}, zone);
    },
    SET_SELECTED_LANE(state, lane) {
        state.selectedLane = Object.assign({}, lane);
    },
    SET_CONFIGURED_LANE_QR(state, qr) {
        state.configuredLaneQRCode = qr;
    },
    SET_LOADING_STATUS(state, status) {
        state.loadingStatus = { ...state.loadingStatus, [status.property]: { "pending": status.pending } }
    },
    SET_ZONE_CONFIG_INFO(state, data){
        state.zoneConfigInfo = data;
    },
};
const actions = {
    async getZones({ commit }, zoneData) {
        try {
            commit("SET_LOADING_STATUS", { property: "ZONES", pending: true });
            const zones = await API.getZonesOverview(zoneData);
            commit("SET_ZONES", zones.data.zones);
            commit("SET_LOADING_STATUS", { property: "ZONES", pending: false });
        } catch (error) {
            console.log(error);
        }
    },
    async getAllZoneEntrances({ commit }, reqData) {
        try {
            commit("SET_LOADING_STATUS", { property: "ZONE_ENTRANCES", pending: true });
            const result = await API.getZoneSummary(reqData);
            commit("SET_ZONE_ENTRANCES", result?.data?.newData ? result.data.newData : []);
            commit("SET_LOADING_STATUS", { property: "ZONE_ENTRANCES", pending: false });
        } catch (error) {
            console.log(error);
        }
    },
    async getSelectedZoneEntrances({ commit }, reqData) {
        try {
            commit("SET_LOADING_STATUS", { property: "ZONE_ENTRANCES", pending: true });
            const result = await API.getZoneSummary(reqData);
            commit("SET_SELECTED_ZONE_ENTRANCES", result?.data?.newData ? result.data.newData : []);
            commit("SET_LOADING_STATUS", { property: "ZONE_ENTRANCES", pending: false });
        } catch (error) {
            console.log(error);
        }
    },
};

const getters = {
    getterUser: (state) => {
        return state.user;
    },
    getterAppSteps: (state) => {
        return state.appSteps
    },
    getterLoadingStatus: (state) => (property) => {
        if (Object.hasOwnProperty.call(state.loadingStatus, property)) {
            return state.loadingStatus[property]["pending"]
        }
        return false;
    },
    getterZones: (state) => {
        let zones = state.zones.sort(function (a, b) {
            return Number(a.zcode) - Number(b.zcode);
        });
        return zones;
    },
    getterSelectedZone: (state) => {
        return state.selectedZone
    },
    getterSelectedLane: (state) => {
        return state.selectedLane
    },
    getterConfiguredLaneQRCode: (state) => {
        return state.configuredLaneQRCode
    },
    getterLaneConfigInfo: (state) => {
        return state.zoneConfigInfo;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations

}